import { Button, Modal } from 'react-bootstrap';

function NoImage({ visible, onClose }) {
    return (
        <Modal show={visible} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>No Image Selected</Modal.Title>
            </Modal.Header>
            <Modal.Body>Please select an image to upload.</Modal.Body>
            <Modal.Footer>
                <Button
                    className="w-full"
                    variant="secondary" onClick={onClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default NoImage;
