import React from 'react';

function ModelUsing({ activeTab, setActiveTab }) {
  return (
    <div className="w-full max-w-sm mx-auto font-redhat mb-8">
      <form className="bg-lightBlue shadow-md rounded-3xl px-8 pt-6 pb-4">
        <div className="flex justify-between">
          <button
            type="button"
            onClick={() => setActiveTab('left')}
            className={`${
              activeTab === 'left' ? 'bg-gold' : 'bg-turqoise'
            } hover:bg-gold text-white font-bold shadow-lg py-2 px-4 mx-2 rounded-full w-full focus:outline-none focus:shadow-outline flex items-center justify-center transition-colors duration-300`}
          >
            Closest Art Pieces
          </button>
          <button
            type="button"
            onClick={() => setActiveTab('right')}
            className={`${
              activeTab === 'right' ? 'bg-gold' : 'bg-turqoise'
            } hover:bg-gold text-white font-bold shadow-lg py-2 px-4 mx-2 rounded-full w-full focus:outline-none focus:shadow-outline flex items-center justify-center transition-colors duration-300`}
          >
            Genre Prediction
          </button>
        </div>
      </form>
    </div>
  );
}

export default ModelUsing;
