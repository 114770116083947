import React, { useState } from 'react';
import ImageUpload from './front/ImageUpload';
import ModelUsing from './front/ModelUsing';

function App() {
  const [activeTab, setActiveTab] = useState('left');

  return (
    <div className="min-h-screen bg-darkBlue flex flex-col items-center justify-center font-redhat">
      <h1 className="text-4xl text-pink font-rh mb-4 mt-10">AI Art Piece Classifier</h1>
      <p className="text-lg text-pink mb-8">Upload an image to get started.</p>
      <ModelUsing activeTab={activeTab} setActiveTab={setActiveTab} />
      <ImageUpload activeTab={activeTab} />
    </div>
  );
}

export default App;
