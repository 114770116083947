import React, { useState } from 'react';
import axios from 'axios';
import { ClipLoader } from 'react-spinners';
import NoImage from './NoImage';

function ImageUpload({ activeTab }) {
    const [selectedFile, setSelectedFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [responseTop5, setResponseTop5] = useState(null);
    const [responseGenre, setResponseGenre] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [noImage, setNoImage] = useState(false);
    const [uploadError, setUploadError] = useState(null);


    const closeModal = () => {
        setNoImage(false);
    }

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!selectedFile) {
            setNoImage(true);
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile);

        setIsUploading(true);
        setResponseTop5(null);
        setResponseGenre(null);
        setUploadError(null);

        try {
            if (activeTab === 'left') {
                const res = await axios.post('https://whatartpiece.art/test-connection', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setResponseTop5(res.data);
            } else {
                const res = await axios.post('https://whatartpiece.art/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                setResponseGenre(res.data);
            }
        } catch (error) {
            console.error('Error uploading the image:', error);
            setUploadError('Failed to upload image. Please try again.');
        } finally {
            setIsUploading(false);
        }
    }

    return (
        <div className="flex flex-col items-center font-redhat">
            <div className="w-full max-w-sm mx-auto font-redhat mb-8">
                <form onSubmit={handleSubmit} className="bg-lightBlue shadow-md rounded-3xl px-8 pt-6 pb-4">
                    <div className="mb-4 flex justify-center items-center">
                        <input
                            id="file-input"
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                            className="hidden"
                            aria-label="File Upload Input"
                            disabled={isUploading}
                        />
                        <label
                            htmlFor="file-input"
                            className={`flex items-center text-white justify-center bg-turqoise hover:bg-gold shadow-md font-bold py-2 px-4 rounded-full cursor-pointer transition-colors duration-300 ${isUploading ? 'opacity-50 cursor-not-allowed' : ''}`}
                            aria-label="Choose an image file to upload"
                        >
                            {selectedFile ? 'Change Image' : 'Choose File'}
                        </label>
                    </div>
                    {preview && (
                        <div className="mb-4">
                            <h3 className="text-lg font-semibold text-white">Image Preview:</h3>
                            <img src={preview} alt="Selected" className="mt-2 w-full h-auto rounded" />
                        </div>
                    )}
                    {uploadError && (
                        <div className="mb-4 text-red-500 text-sm">
                            {uploadError}
                        </div>
                    )}
                    <div className="flex items-center justify-center">
                        <button
                            type="submit"
                            disabled={isUploading}
                            className={`${isUploading ? 'cursor-not-allowed opacity-50' : 'bg-turqoise hover:bg-gold'
                                } text-white font-bold shadow-lg py-2 px-4 rounded-full w-full focus:outline-none focus:shadow-outline flex items-center justify-center transition-colors duration-300`}
                        >
                            {isUploading ? <ClipLoader size={20} color="#FFFFFF" /> : 'Upload Image'}
                        </button>
                    </div>
                </form>
            </div>
            <div className="w-full mx-auto font-redhat px-4 my-5">
                {responseTop5 && (
                    <div className="bg-purple shadow-md rounded-lg p-8">
                        <h3 className="text-2xl font-semibold text-white mb-6">Recognition Results:</h3>
                        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-6">
                            {responseTop5.results.map((result, index) => {
                                const imageUrl = `http://https://whatartpiece.art/images/${encodeURIComponent(result.image_path)}`;
                                return (
                                    <li key={index}>
                                        <div className="bg-white rounded-lg overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300">
                                            <img
                                                src={imageUrl}
                                                alt={`Match ${index + 1}: ${result.label}`}
                                                className="w-full h-auto object-cover"
                                                loading="lazy"
                                                onError={(e) => { e.target.src = '/path-to-placeholder-image.jpg'; }}
                                            />
                                            <div className="p-4">
                                                <p className="text-gray-800 font-semibold text-lg mb-2">{result.description}</p>
                                                <p className="text-gray-800 text-lg">- {result.artist}</p>
                                                <p className="text-gray-800 text-md">(#{index + 1} Closest Match | Similarity Score: {result.similarity_score.toFixed(4)})</p>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}
                {responseGenre && (
                    <div className="bg-purple shadow-md rounded-lg p-8">
                        <h3 className="text-2xl font-semibold text-white mb-6">Most Similar Genre:</h3>
                        <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
                            {responseGenre.results.slice(0, 3).map((result, index) => {
                                const imageUrl = `https://whatartpiece.art/images/${encodeURIComponent(result.image_path)}`;
                                return (
                                    <li key={index}>
                                        <div className="bg-white rounded-lg justify-center items-center text-center overflow-hidden shadow-md hover:shadow-xl transition-shadow duration-300">
                                            <div className="p-4">
                                                <p className="text-gray-800 font-semibold text-lg mb-2">
                                                    {result.label.replace(/_/g, ' ')}
                                                </p>
                                                <p className="text-gray-800 text-lg">
                                                    This piece is reminiscent of pieces by{' '}
                                                    <strong>{result.artist}</strong>
                                                </p>
                                                <p className="text-gray-800 text-md">
                                                    (#{index + 1} Closest Match | Similarity Score: {result.similarity_score.toFixed(4)})
                                                </p>
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                )}

                <NoImage visible={noImage} onClose={closeModal} />
            </div>
        </div>
    );
}

export default ImageUpload;
